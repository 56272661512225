var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Banner',{attrs:{"banner_img":_vm.resBanners,"banner_en":"NEWS AND TRENDS","banner_cn":"新闻动态"}}),_c('div',{staticClass:"contain clear"},[_c('div',{staticClass:"c-space service wow fadeInUp animated"},[_vm._m(0),_c('div',{staticClass:"newscon marginT20 wow fadeInUp animated"},[_c('ul',{staticClass:"newsul"},_vm._l((_vm.companyNewsList),function(item,index){return _c('li',{key:index,staticClass:"clear"},[_c('div',{staticClass:"nlink",on:{"click":function($event){_vm.splicingUrl(
                  {
                    base: '/news-details?id=' + item.infoId,
                    index:
                      index - 0 + 1 + (_vm.companyPageNo - 1) * _vm.companyPageSize,
                    type: 'company',
                  },
                  item.externallinks
                )}}},[_c('div',{staticClass:"newsImg fl"},[_c('img',{attrs:{"src":item.infoImage
                      ? _vm.companyImgSrc(item)
                      : require('../../assets/images/common/moren.jpg'),"alt":"..."}})]),_c('div',{staticClass:"newstxtcon fl"},[_c('div',{staticClass:"news_tit fontsize18 color333"},[_c('div',{staticClass:"textSl1"},[_vm._v(" "+_vm._s(item.infoName)+" ")])]),_c('div',{staticClass:"newstime fontsize16 color666"},[_c('span',[_vm._v(" "+_vm._s(_vm.onConversion2(item.publishTime))+" ")])]),_vm._m(1,true)])])])}),0)]),_c('div',{staticClass:"pagecon"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.companyPageSize,"total":_vm.companyTotal},on:{"size-change":_vm.getCompanyPage,"current-change":_vm.getCompanyPage}})],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_title"},[_c('div',{staticClass:"noticetit"},[_vm._v("广州工控服务管理有限公司新闻")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news_detai marginT30"},[_c('span',[_vm._v("查看详情")]),_c('div',{staticClass:"jiantou"})])
}]

export { render, staticRenderFns }