<template>
  <div>
    <Banner
      :banner_img="resBanners"
      banner_en="NEWS AND TRENDS"
      banner_cn="新闻动态">
    </Banner>
    <div class="contain clear">
      <!-- <div class="c-space bloc wow fadeInUp animated">
                <div class="news_title ">
                    <div class="noticetit ">
                        广州工业投资控股集团有限公司要闻
                    </div>
                    <div class="title-en">IMPORTANT NEWS</div>
                </div>
                <div class="newscon marginT20 wow fadeInUp animated">
                    <ul class="newsul">
                        <li
                            class="clear"
                            v-for="(item, index) in impoptantNewsList"
                            :key="index"
                        >
                            <div
                                class="nlink"
                                @click="
                                    splicingUrl({
                                        base: '/news-details?id=' + item.infoId,
                                        index:
                                            index -
                                            0 +
                                            1 +
                                            (impoptantPageNo - 1) *
                                                impoptantPageSize,
                                        type: 'impoptant'
                                    },item.externallinks)
                                "
                            >
                                <div class="newsImg fl">
                                    <img
                                        :src="
                                            item.infoImage
                                                ? companyImgSrc(item)
                                                : require('../../assets/images/common/moren.jpg')
                                        "
                                        alt="..."
                                    />
                                </div>
                                <div class="newstxtcon fl">
                                    <div class="news_tit fontsize18 color333">
                                        <div class="textSl1">
                                            {{ item.infoName }}
                                        </div>
                                    </div>
                                    <div class="newstime fontsize16 color666">
                                        <span>
                                            {{
                                                onConversion2(item.publishTime)
                                            }}
                                        </span>
                                    </div>
                                    <div class="news_detai marginT30">
                                        <span>查看详情</span>
                                        <div class="jiantou"></div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="pagecon">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :page-size="impoptantPageSize"
                        :total="impoptantTotal"
                        @size-change="getImpoptantPage"
                        @current-change="getImpoptantPage"
                    >
                    </el-pagination>
                </div>
            </div> -->
      <div class="c-space service wow fadeInUp animated">
        <div class="news_title">
          <!-- <div class="title-en">company NEWS</div> -->
          <div class="noticetit">广州工控服务管理有限公司新闻</div>
        </div>
        <div class="newscon marginT20 wow fadeInUp animated">
          <ul class="newsul">
            <li
              class="clear"
              v-for="(item, index) in companyNewsList"
              :key="index">
              <div
                class="nlink"
                @click="
                  splicingUrl(
                    {
                      base: '/news-details?id=' + item.infoId,
                      index:
                        index - 0 + 1 + (companyPageNo - 1) * companyPageSize,
                      type: 'company',
                    },
                    item.externallinks
                  )
                ">
                <div class="newsImg fl">
                  <!-- <img :src="item.infoImage" alt="..." /> -->
                  <img
                    :src="
                      item.infoImage
                        ? companyImgSrc(item)
                        : require('../../assets/images/common/moren.jpg')
                    "
                    alt="..." />
                </div>
                <div class="newstxtcon fl">
                  <div class="news_tit fontsize18 color333">
                    <div class="textSl1">
                      {{ item.infoName }}
                    </div>
                  </div>
                  <!-- <div class="news_fcon marginT10">
                                        {{ item.cont }}
                                    </div> -->
                  <div class="newstime fontsize16 color666">
                    <span>
                      {{ onConversion2(item.publishTime) }}
                    </span>
                  </div>
                  <div class="news_detai marginT30">
                    <span>查看详情</span>
                    <div class="jiantou"></div>
                    <!-- <img
                                            src="../../assets/images/news/jiantouhuang.png"
                                            alt="..."
                                        /> -->
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="pagecon">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="companyPageSize"
            :total="companyTotal"
            @size-change="getCompanyPage"
            @current-change="getCompanyPage">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/common/Banner.vue";
export default {
  components: { Banner },
  data() {
    return {
      // baner
      resBanners: [],
      //工控集团要闻
      impoptantNewsList: [],
      impoptantTotal: 1, // 总数
      impoptantPageNo: 1, // 当前页
      impoptantPageSize: 5, // 每页显示条数
      //工控服务新闻
      companyNewsList: [],
      companyTotal: 1, // 总数
      companyPageNo: 1, // 当前页
      companyPageSize: 5, // 每页显示条数
    };
  },
  mounted() {
    this.getBanners();
    this.getNewList2();
    this.getInfoManageList();
    new this.$wow.WOW({
      live: false,
      offset: 0,
    }).init();
  },
  methods: {
    // 时间转换
    onConversion2(date) {
      // console.log(date);
      if (!date) return "暂无时间";
      var nDate = new Date(date.replace(new RegExp(/-/gm), "/"));
      // let nDate = new Date(date),
      var y = nDate.getFullYear(),
        m = ("0" + (nDate.getMonth() + 1)).slice(-2),
        d = ("0" + nDate.getDate()).slice(-2);
      // console.log(date)
      return y + "-" + m + "-" + d;
    },
    onConversion1(date) {
      var arr = date.split("T");
      var d = arr[0];
      var darr = d.split("-");

      var dd =
        parseInt(darr[0]) +
        "-" +
        parseInt(darr[1]) +
        "-" +
        parseInt(darr[2]) +
        " ";
      return dd;
    },
    // 获取banner
    async getBanners() {
      let res = await this.$request.getImageAdvertisingList({
        advertisingPosition: "2",
      });
      if (res.code === 200) {
        this.resBanners = [];
        this.resBanners = res.rows;
      }
    },
    // url拼接
    splicingUrl({ base, index, type }, externallinks) {
      console.log(externallinks);
      if (externallinks) {
        window.open(externallinks);
      } else {
        this.$router.push({ path: base + "&index=" + index + "&type=" + type });
      }
      // return base + "&index=" + index + "&type=" + type;
    },
    // 图片路径拼接
    impoptantImgsrc(item) {
      return item.resourcesSpaceData && item.resourcesSpaceData.resourceUrl
        ? item.resourcesSpaceData.resourceUrl.indexOf("http:") != -1
          ? item.resourcesSpaceData.resourceUrl
          : "http://" +
            item.resourcesSpaceData.imgDomainName +
            // ":8080" +
            item.resourcesSpaceData.resourceUrl
        : require("../../assets/images/common/moren.jpg");
    },
    // 图片路径拼接
    companyImgSrc(item) {
      return item && item.infoImage
        ? this.$store.state.baseUrl + item.infoImage
        : require("../../assets/images/common/moren.jpg");
    },
    async getNewList2() {
      // let res = await this.$request.getNewList2({
      //     pageNo: this.impoptantPageNo,
      //     pageSize: this.impoptantPageSize,
      //     channelName: "集团要闻"
      // });
      let res = await this.$request.getInfoManageList({
        pageNo: this.impoptantPageNo,
        pageSize: this.impoptantPageSize,
        infoColumn: "4",
      });
      this.impoptantNewsList = res.rows;
      this.impoptantTotal = res.total;
      // console.log(this.impoptantNewsList);
    },
    async getInfoManageList() {
      let res = await this.$request.getInfoManageList({
        pageNo: this.companyPageNo,
        pageSize: this.companyPageSize,
        infoColumn: "0",
      });
      this.companyNewsList = res.rows;
      this.companyTotal = res.total;
      // console.log(res);
    },
    async getImpoptantPage(page) {
      // 获取第 page 页数据
      // console.log(page);
      this.impoptantPageNo = page;
      this.getNewList2();
    },
    async getCompanyPage(page) {
      // 获取第 page 页数据
      // console.log(page);
      this.companyPageNo = page;
      this.getInfoManageList();
    },
  },
};
</script>

<style lang="scss" scoped>
.nlink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.noticetit {
  width: auto;
  font-weight: 700;
  border-bottom: none;
}
.noticetit::before {
  content: "";
  display: block;
  width: 7.81vw;
  height: 0.05vw;
  background: #e0e0e0;
  position: absolute;
  bottom: 0vw;
  left: 50%;
  margin-left: -3.91vw;
}
.contain {
  width: 62.5vw;
  margin: 1.875vw auto 2.08vw;

  .news_title {
    text-align: center;
    padding: 1.0417vw 0 0 0;
    position: relative;
    .title-cn {
      font-size: 1.5625vw;
      color: #333333;
      font-weight: bold;
    }
  }

  .c-space {
    // float: left;
    // width: 30.2083vw;
    width: 100%;
    box-shadow: 0vw 0.1042vw 0.2604vw 0vw rgba(0, 0, 0, 0.16);

    // &.service {
    //   margin-left: 2.0833vw;
    // }
  }

  .Pagination {
    padding: 1.5625vw 0;
  }
}

.newsul {
  width: 100%;
  border-top: 0.0521vw solid #eeeeee;

  li {
    padding: 1.0417vw;
    cursor: pointer;
    box-sizing: border-box;
    transition: 0.6s ease;
    position: relative;
    .nlink {
      position: relative;
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 0.0521vw;
        position: absolute;
        left: 0;
        bottom: -1.0417vw;
        background: #eeeeee;
      }
    }

    &:hover {
      background: #f3ca00;
      color: #fff;

      .news_detai {
        color: #fff;
        .jiantou {
          width: 1.9271vw;
          height: 0.3125vw;
          display: inline-block;
          margin-left: 0.2604vw;
          background: url(../../assets/images/news/jiantoubai.png) no-repeat
            center center;
          background-size: 100%;
        }
      }
    }

    &:hover .news_tit {
      color: #fff !important;
    }

    &:hover .newstime {
      color: #fff !important;
    }
  }

  .newsImg {
    width: 7.2917vw;
    height: 5.4167vw;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .newstxtcon {
    // width: 19.7917vw;
    flex-shrink: 0;
    flex: 1;
    box-sizing: border-box;
    margin-left: 1.0417vw;
    padding: 0.5208vw 0 0.5208vw 0;
    position: relative;
  }

  .news_tit {
    position: relative;
    font-weight: 700;
    .textSl1 {
      height: 20px;
    }
  }

  .newstime {
    position: absolute;
    left: 0;
    bottom: 0.5208vw;
  }

  .news_fcon {
    font-size: 0.8333vw;
    line-height: 1.25vw;
    height: 3.75vw;
    color: #3d3d3d;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-align: justify;
  }

  .news_detai {
    color: #f3ca00;
    font-size: 0.7292vw;
    text-align: right;
    .jiantou {
      width: 1.9271vw;
      height: 0.3125vw;
      display: inline-block;
      margin-left: 0.2604vw;
      background: url(../../assets/images/news/jiantouhuang.png) no-repeat
        center center;
      background-size: 100%;
    }
  }

  .newstxtcon {
    width: 19.7vw;
    margin-left: 1.0417vw;
    padding: 0.5208vw 0 0.5208vw 0;
    position: relative;
  }

  .news_tit {
    position: relative;
    font-weight: 700;
  }

  .newstime {
    position: absolute;
    left: 0;
    bottom: 0.5208vw;
  }

  .news_fcon {
    font-size: 0.8333vw;
    line-height: 1.25vw;
    height: 3.75vw;
    color: #3d3d3d;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-align: justify;
  }

  .news_detai {
    color: #f3ca00;
    font-size: 0.7292vw;
    text-align: right;

    img {
      width: 1.9271vw;
      margin-left: 0.2604vw;
      vertical-align: top;
      margin-top: 0.1563vw;
    }
  }
}
.pagecon {
  text-align: center;
  padding: 1.04vw 0;
}
// @media (min-width: 1920px) {
//   .nlink {
//     display: block;
//     cursor: pointer;
//   }

//   .contain {
//     width: 62.5vw;
//     margin: 1.875vw auto 2.0833vw;

//     .news_title {
//       text-align: center;
//       padding: 1.0417vw 0 0 0;
//       position: relative;

//       // .title-en {
//       //     font-size: 1.5625vw;
//       //     font-weight: bold;
//       //     color: #333333;
//       //     opacity: 0.2;
//       // }

//       .title-cn {
//         // font-size: 0.9375vw;
//         font-size: 1.5625vw;
//         color: #333333;
//         font-weight: bold;
//         // position: absolute;
//         // top: 1.9792vw;
//         // left: 50%;
//         // transform: translate(-50%, 0);
//       }
//     }

//     .c-space {
//       //   float: left;
//       //   width: 30.2083vw;
//       width: 100%;
//       box-shadow: 0vw 0.1042vw 0.2604vw 0vw rgba(0, 0, 0, 0.16);

//       //   &.service {
//       //     margin-left: 2.0833vw;
//       //   }
//     }

//     .Pagination {
//       padding: 1.5625vw 0;
//     }
//   }

//   .newsul {
//     width: 100%;
//     border-top: 0.0521vw solid #eeeeee;

//     li {
//       padding: 1.0417vw;
//       cursor: pointer;
//       box-sizing: border-box;
//       transition: 0.6s ease;
//       position: relative;

//       &::before {
//         content: "";
//         display: block;
//         width: 100%;
//         height: 0.0521vw;
//         position: absolute;
//         left: 1.0417vw;
//         bottom: 0;
//         background: #eeeeee;
//       }

//       &:hover {
//         background: #f3ca00;
//         color: #fff;

//         .news_detai {
//           color: #fff;
//           .jiantou {
//             width: 37px;
//             height: 6px;
//             display: inline-block;
//             margin-left: 5px;
//             background: url(../../assets/images/news/jiantoubai.png) no-repeat
//               center center;
//             background-size: 100%;
//           }
//         }
//       }

//       &:hover .news_tit {
//         color: #fff !important;
//       }

//       &:hover .newstime {
//         color: #fff !important;
//       }
//     }

//     .newsImg {
//       width: 7.2917vw;
//       height: 5.4167vw;
//       overflow: hidden;

//       img {
//         display: block;
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//       }
//     }

//     .newstxtcon {
//       width: 19vw;
//       margin-left: 1.0417vw;
//       padding: 0.5208vw 0 0.5208vw 0;
//       position: relative;
//     }

//     .news_tit {
//       position: relative;
//       font-weight: 700;
//     }

//     .newstime {
//       position: absolute;
//       left: 0;
//       bottom: 0.5208vw;
//     }

//     .news_fcon {
//       font-size: 0.8333vw;
//       line-height: 1.25vw;
//       height: 3.75vw;
//       color: #3d3d3d;
//       display: -webkit-box;
//       -webkit-box-orient: vertical;
//       -webkit-line-clamp: 3;
//       overflow: hidden;
//       text-align: justify;
//     }

//     .news_detai {
//       color: #f3ca00;
//       font-size: 0.7292vw;
//       text-align: right;
//       .jiantou {
//         width: 37px;
//         height: 6px;
//         display: inline-block;
//         margin-left: 5px;
//         background: url(../../assets/images/news/jiantouhuang.png) no-repeat
//           center center;
//         background-size: 100%;
//       }
//       /* img {
//                 width: 37px;
//                 margin-left: 5px;
//                 vertical-align: top;
//                 margin-top: 3px;
//             } */
//     }

//     .newstxtcon {
//       width: 19vw;
//       margin-left: 1.0417vw;
//       padding: 0.5208vw 0 0.5208vw 0;
//       position: relative;
//     }

//     .news_tit {
//       position: relative;
//       font-weight: 700;
//     }

//     .newstime {
//       position: absolute;
//       left: 0;
//       bottom: 0.5208vw;
//     }

//     .news_fcon {
//       font-size: 0.8333vw;
//       line-height: 1.25vw;
//       height: 3.75vw;
//       color: #3d3d3d;
//       display: -webkit-box;
//       -webkit-box-orient: vertical;
//       -webkit-line-clamp: 3;
//       overflow: hidden;
//       text-align: justify;
//     }

//     .news_detai {
//       color: #f3ca00;
//       font-size: 0.7292vw;
//       text-align: right;

//       img {
//         width: 1.9271vw;
//         margin-left: 0.2604vw;
//         vertical-align: top;
//         margin-top: 0.1563vw;
//       }
//     }
//   }

//   .pagecon {
//     text-align: center;
//     padding: 20px 0;
//   }
// }

.pagecon /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #f3ca00;
  color: #fff;
}

.pagecon /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #f3ca00;
}
</style>
